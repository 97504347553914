exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-blogposts-js": () => import("./../../../src/pages/all-blogposts.js" /* webpackChunkName: "component---src-pages-all-blogposts-js" */),
  "component---src-pages-all-projects-js": () => import("./../../../src/pages/all-projects.js" /* webpackChunkName: "component---src-pages-all-projects-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blogpost-details-js": () => import("./../../../src/templates/blogpost-details.js" /* webpackChunkName: "component---src-templates-blogpost-details-js" */),
  "component---src-templates-project-details-js": () => import("./../../../src/templates/project-details.js" /* webpackChunkName: "component---src-templates-project-details-js" */)
}

